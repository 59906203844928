/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    code: "code",
    pre: "pre"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      children: "Colors"
    }), "\n", _jsxs(_components.p, {
      children: ["If you'd like to customize your values for kind of colors, use the ", _jsx(_components.code, {
        children: "theme.colors"
      }), " section of your theme."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-diffjs",
        children: "  // theme.js\n  import { th, generateHexAlphaVariants } from '@xstyled/styled-components'\n\n  export const theme = {\n    colors: generateHexAlphaVariants({\n      // ...\n+     'deep-blue': '#00008b',\n+     'primary': th.color('red-600'),\n    }),\n  }\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
